import { applyMiddleware } from "redux";
import { compose } from "redux";
import { createStore } from "redux";
import logger from "redux-logger";
import { rootReducer } from './root-reducer'
import { thunk } from "redux-thunk";


const middleWares = [
  process.env.NODE_ENV !== "production" && logger,
  thunk,
].filter(Boolean); // >>---> process.env.NODE_ENV --> we use this to check if the environment is either development or production and configure things accordingly

const composeEnhancer =
  (process.env.NODE_ENV !== "production" &&
    window &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;



const composeEnhancers = composeEnhancer(applyMiddleware(...middleWares));

export const store = createStore(rootReducer, undefined, composeEnhancers);
