import React from 'react'

const ImageNotFound = () => {
  return (
    <div className='w-full image-not-found'>
      <h2>You are not able to view this ad as it was removed from TikTok due to a violation of TikTok's terms.</h2>
    </div>
  )
}

export default ImageNotFound
