import React, { useEffect } from "react";
import "./App.scss";
import Footer from "./Components/Footer";

import { Route, Routes } from 'react-router-dom';
import Home from "./Pages/Home";
import Faqs from "./Pages/Faqs";
import Topbar from "./Components/Topbar";
import { useDispatch } from "react-redux";
import { setTikTokAds } from "./store/tiktokAds/tiktokAds.actions";




function App() {

  return (
    <div className="App">
      <Topbar/>
      <Routes>
                    <Route path="/" element={<Home/>}/>
                     <Route path="/faqs" element={<Faqs/>}/>
        </Routes>

        <Footer/>
    </div>

  );
}

export default App;
