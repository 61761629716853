import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';

const Topbar = () => {
  const location = useLocation();

  // Add state for controlling dropdown visibility
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
   // Function to close dropdown
   const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  return (
    <div className="flex flex-col items-center justify-between w-full lg:flex-row absolute top-0 left-0 right-0 z-50 pb-0  h-22 navbar ">
      <div
        className="mt-7 mx-auto h-16 bg-white rounded-[15px] flex justify-between items-center px-10 lg:w-4/5 w-11/12"
       >
        <a
          href="/"
          aria-current="page"
          aria-label="home"
          className="overflow-visible w-30 max-w-30 flex items-center gap-1">
          <svg fontSize="28px" viewBox="0 0 48 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"><g clipPath="url(#Icon_Color-TikTok_Logo_Dark_svg__a)" fillRule="evenodd" clipRule="evenodd"><path d="M32.37 2.73c-.23-.87-.35-1.79-.35-2.73h-7.85v31.2a6.5 6.5 0 0 1-6.49 6.51 6.5 6.5 0 0 1-6.48-6.51 6.5 6.5 0 0 1 8.53-6.18V16.6A14.71 14.71 0 0 0 3 31.2c0 8.14 6.57 14.74 14.68 14.74 8 0 14.5-6.41 14.69-14.4V15.58a18.64 18.64 0 0 0 10.58 3.28v-7.89c-5.1 0-9.37-3.5-10.58-8.24Z" fill="#25F4EE"></path><path d="M34.41 4.79c-.22-.87-.34-1.79-.34-2.73h-7.85v31.2a6.5 6.5 0 0 1-6.49 6.51 6.5 6.5 0 0 1-6.49-6.51 6.5 6.5 0 0 1 8.54-6.19v-8.41a14.71 14.71 0 0 0-16.73 14.6C5.05 41.4 11.62 48 19.73 48c8 0 14.5-6.42 14.68-14.4V17.64A18.64 18.64 0 0 0 45 20.9v-7.88c-5.1 0-9.38-3.5-10.59-8.24Z" fill="#000"></path><path d="M32.22 2.06a10.97 10.97 0 0 0 4.1 6.66 10.95 10.95 0 0 1-2.25-6.66h-1.85Zm10.73 10.78v6.02c-3.93 0-7.57-1.21-10.58-3.28V31.54a14.71 14.71 0 0 1-14.69 14.4c-3.53 0-6.77-1.25-9.3-3.34 2.68 3.3 6.77 5.4 11.35 5.4 8 0 14.5-6.42 14.68-14.4V17.64A18.64 18.64 0 0 0 45 20.9v-7.88c-.7 0-1.39-.07-2.05-.2ZM19.73 18.5v6.5a6.5 6.5 0 0 0-5.5 11.7 6.5 6.5 0 0 1 7.55-9.64v-8.41c-.67-.1-1.35-.15-2.05-.15Z" fill="#FE2C55"></path></g><defs><clipPath id="Icon_Color-TikTok_Logo_Dark_svg__a"><path fill="#fff" d="M0 0h48v48H0z"></path></clipPath></defs></svg>

          {/* Full logo for larger screens */}
          <img
            src="Images/tiktok-logo.svg"
            alt="Full logo"
            className="hidden my-auto align-middle sm:inline-block w-20"
          />
          
        </a>

            {/* Show in large screen */}
        <div className="hidden sm:ml-auto lg:block">
          <div className="flex space-x-4 ">

            <div className="text-sm font-semibold inline-flex items-center px-1 pt-1">
              <Link
                to='/'
                className={` border-transparent text-[#16182380] pb-1 border-b-2 hover:text-black ${
                  location.pathname === '/' ? 'active' : ''
                }`}
              >
                Ad Library
              </Link>
            </div>
            <div className="text-sm font-semibold inline-flex items-center px-1 pt-1">
              <a
                href="#"
                className={` border-transparent text-[#16182380] pb-1 border-b-2 hover:text-black ${
                  location.pathname === '#' ? 'active' : ''
                }`}
              >
                All ads report
              </a>
            </div>
            <div className="text-sm font-semibold inline-flex items-center px-1 pt-1">
              <a
                href="#"
                className={` border-transparent text-[#16182380] pb-1 border-b-2 hover:text-black ${
                  location.pathname === '#' ? 'active' : ''
                }`}
              >
                Other commercial content
              </a>
            </div>
            <div className="text-sm font-semibold inline-flex items-center px-1 pt-1">
              <Link
                to='/faqs'
                className={` border-transparent text-[#16182380] pb-1 border-b-2 hover:text-black ${
                  location.pathname === '/faqs' ? 'active' : ''
                }`}
              >
                FAQs
              </Link>
            </div>
          </div>
        </div>

        {/* Toggle */}
        <div className="relative lg:hidden inline-block text-left">
          <button
            type="button"
            onClick={toggleDropdown}
            className="inline-flex w-full justify-center gap-x-1.5 rounded-lg bg-[#000] border px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50"
            id="menu-button"
            aria-expanded={isDropdownOpen ? "true" : "false"}
            aria-haspopup="true"
          >
            <img
              className="sm:w-6 w-5"
              src="Images\toggle.png"
              alt="Your Company"
            />
          </button>

          {/* Dropdown menu */}
          {isDropdownOpen && (
            <div
              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none animate__animated animate__fadeInLeft"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex={-1}
            >
              <Link
                to='/'
                onClick={closeDropdown}
                className="text-black font-normal hover:bg-red-500 hover:text-white hover:font-medium rounded-sm px-3 py-2 text-sm block"
                role="menuitem"
                tabIndex={-1}
                id="menu-item-0"
              >
                Ad Library
              </Link>
              <a
                href=""
                onClick={closeDropdown}
                className="text-black font-normal hover:bg-red-500 hover:text-white hover:font-medium rounded-sm px-3 py-2 text-sm block"
                role="menuitem"
                tabIndex={-1}
                id="menu-item-0"
              >
                All ads report
              </a>
              <a
                href=""
                onClick={closeDropdown}
                className="text-black font-normal hover:bg-red-500 hover:text-white hover:font-medium rounded-sm px-3 py-2 text-sm block"
                role="menuitem"
                tabIndex={-1}
                id="menu-item-0"
              >
                other commercial content
              </a>
              <Link
                to='/faqs'
                onClick={closeDropdown}
                className="text-black font-normal hover:bg-red-500 hover:text-white hover:font-medium rounded-sm px-3 py-2 text-sm block"
                role="menuitem"
                tabIndex={-1}
                id="menu-item-0"
              >
                FAQs
              </Link>

            </div>
          )}
        </div>
      </div>


    </div>
  );
};

export default Topbar;
