import React from 'react'
import ResultCard from './ResultCard'

const Results = ({ ads, total }) => {
    return (
        <div>
            <div className="bg-[#f7f8f9] HomepageContainer min-w-fit z-10">
                <div className="w-11/12 max-w-[1092px] mx-auto border-t pt-20 pb-28">
                    <h2 className="pb-1 text-4xl font-bold text-gray-900 leading-tight">Search Results</h2>
                    <p className="pb-8 text-sm font-medium  text-[#121212b6] leading-relaxed">Total ads: {total}</p>
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 mt-4">
                        {/* Cards */}


                        {
                            ads.map((ad, index) => {
                                
                                return ( ad && <ResultCard key={index} heading={ad.name} firstShown={ad.first_shown_date} lastShown={ad.last_shown_date} uniqueUsers={ad.estimated_audience} url={ad.videos}/> )
                            })
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Results
