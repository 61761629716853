// export const TIKTOK_ADS_ACTION_TYPES = {
//   SET_ADS: 'tiktokAds/SET_ADS'
// }

export const TIKTOK_ADS_ACTION_TYPES = {
  SET_FORM_FIELDS: 'tiktokAds/SET_FORM_FIELDS',
  FETCH_ADS_START: 'tiktokAds/FETCH_ADS_START',
  UPDATE_ADS_START: 'tiktokAds/UPDATE_ADS_START',
  UPDATE_ADS_SUCCESS: 'tiktokAds/UPDATE_ADS_SUCCESS',
  FETCH_ADS_SUCCESS: 'tiktokAds/FETCH_ADS_SUCCESS',
  FETCH_ADS_FAILED: 'tiktokAds/FETCH_ADS_FAILED',
}