import {TIKTOK_ADS_ACTION_TYPES} from './tiktokAds.types'

const TIKTOK_ADS_INITIAL_STATE = {
  adsArrayData: [],
  fetchedData: [],
  isLoading: false,
  error: null,
  hasMore: false,
  searchID: '',
  startTime: null,
  selectedRegion: '',
  selectedAdType: '',
  selectedKeywords: '',
  endTime: null,
  offset: 0,
}

export const tiktokAdsReducer = (state = TIKTOK_ADS_INITIAL_STATE, action = {}) => {
  const { type, payload } = action

  switch(type){
    case TIKTOK_ADS_ACTION_TYPES.SET_FORM_FIELDS:
      return {...state, ...payload}

    case TIKTOK_ADS_ACTION_TYPES.FETCH_ADS_START:
      return {...state, isLoading: true, adsArrayData: [], hasMore: false, error: null, searchID: '', offset: 0};

    case TIKTOK_ADS_ACTION_TYPES.UPDATE_ADS_START:
      return {...state, isLoading: true};

    case TIKTOK_ADS_ACTION_TYPES.UPDATE_ADS_SUCCESS:
      return {...state, offset: state.offset+1}

    case TIKTOK_ADS_ACTION_TYPES.FETCH_ADS_SUCCESS:
      const updatedAdsArray = state.adsArrayData.concat(payload.data);
      return {...state, fetchedData: payload, isLoading: false, adsArrayData: updatedAdsArray, hasMore: payload.has_more, searchID: payload.search_id};

    case TIKTOK_ADS_ACTION_TYPES.FETCH_ADS_FAILED:
      return {...state, error: payload, isLoading: false}

  default:
    return state;
  }

}