import { useEffect, useRef, useState } from 'react';
import Results from '../Components/Results';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import { selectFetchLoadingStatus, selectFetchedData, selectHasMore, selectTikTokAds } from '../store/tiktokAds/tiktokAds.selectors';
import { setFormFieldsData, updateAdsAsync } from '../store/tiktokAds/tiktokAds.actions';
import Loader from '../Components/Loader';
import { countries, countryAbbreviations } from '../utils/JS_Utils/JS_Utils';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import { formatDate } from '../Components/ResultCard';




const formFieldsInitialState = {
  selectedRegion: 'AT',
  selectedAdType: 'All ad types',
  selectedKeywords: '',
}


const Home = () => {
  
  const dispatch = useDispatch();

  const [adFormFields, setAdFormFields] = useState(formFieldsInitialState)
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);
  const [displayDateRangePicker, setDisplayDateRangePicker] = useState(false)

  // console.log(dateRange[0].startDate, dateRange[0].endDate)


  const adsArray = useSelector(selectTikTokAds)
  const loadingStatus = useSelector(selectFetchLoadingStatus);
  const fetchedRawData = useSelector(selectFetchedData);
  const hasMoreEntries = useSelector(selectHasMore);



  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Fire your event here, e.g., call a function
          if(hasMoreEntries){
            dispatch(updateAdsAsync())
          }

          // console.log('Element is in view');
          // Stop observing once the element is in view if you only want to trigger the event once
          // observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0 });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [hasMoreEntries]);



  const onFormDataEntriesChange = (event) => {
    const {name, value} = event.target

    setAdFormFields({...adFormFields, [name]: value});
  }


  const onSelectedRegionChange = (event) => setAdFormFields({...adFormFields, selectedRegion: countryAbbreviations[event.target.value]})

  // const onStartDateChange = (date) => setAdFormFields({...adFormFields, startTime: date})

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(dateRange[0].startDate.getTime() > (new Date()).getTime()){

      setDateRange([{startDate: new Date(), endDate: new Date(), key: 'selection'}])
      dispatch(setFormFieldsData({...adFormFields, startTime: new Date(), endTime: new Date()}))
      return

    } else if(dateRange[0].endDate.getTime() > (new Date()).getTime()){

      setDateRange([{startDate: dateRange[0].startDate, endDate: new Date(), key: 'selection'}])
      dispatch(setFormFieldsData({...adFormFields, startTime: dateRange[0].startDate, endTime: new Date()}))
      return

    }
    
    dispatch(setFormFieldsData({...adFormFields, startTime: dateRange[0].startDate, endTime: dateRange[0].endDate}))
  }
  return (
    <div>
      <div className=''>
        <div
          className="absolute top-0 bottom-0 left-0 right-0 z-0 background-gradient "
          style={{ pointerEvents: "none" }}>
          <img
            src="https://assets-global.website-files.com/64d0ce443a2aa5bfebde64d2/64df5bc08ccc30bf928bdf4b_Background%20Blur.svg"
            loading="eager"
            alt=""
            className="w-full h-[130%] object-cover -mt-[150px]"
            style={{
              minWidth: "100%",
              width: "100%",
            }}
          />
        </div>



        <div className='bg-[#f7f8f9] flex flex-col HomepageContainer pb-32 min-w-fit pt-80'>
          <div className="rounded-xl mx-auto bg-white z-10 lg:py-11 lg:px-9 sm:py-9 sm:px-6 p-5  shadow-lg w-11/12 max-w-[900px]"
            style={{ maxHeight: "700px" }}>


            {/* Heading */}
            <header className='flex items-center justify-center mx-auto leading-snug text-gray-800 sm:pb-12 pb-4'>
              <h1 className="text-center sm:text-[60px] text-4xl font-bold  leading-normal" style={{
                color: "#000000",
                maxWidth: "1000px",
                margin: "auto",
                // fontFamily: "DM Sans",
              }}>Find Ads On
                <span className='pl-4 gradient-text sm:text-[70px] text-4xl'>TIKTOK</span></h1>
            </header>

            {/* Input Form */}
            <div className="bg-white py-5">
              <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-5">
                <div className="">
                  <p className="text-gray-600 text-sm font-semibold pb-2">Ad Target country </p>
                  <select
                    //  className="border-2 w-full h-12 p-2 outline-none rounded focus:border-red-500"
                    onChange={onSelectedRegionChange}
                    name='selectedRegion'
                    className="block  rounded-md border-0 py-3 px-2 w-[100%] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm">
                    {
                      countries.map((country, index) => <option key={index}>{country}</option>)
                    }
                  </select>
                </div>

                <div className="">
                  <p className="text-gray-600 text-base font-semibold pb-2">Ad Type</p>
                  <select 
                    onChange={onFormDataEntriesChange}
                    name='selectedAdType'
                    className="block  rounded-md border-0 py-3 px-2 w-[100%] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm">
                    <option>All ad types</option>
                  </select>
                </div>

                <div className="">
                  <p className="text-gray-600 text-base font-semibold pb-2">Search Date Range</p>
                  <div></div>
                  {/*<DatePicker selected={adFormFields.startTime} name='startDate' onChange={(date) => onStartDateChange(date)} className="block  rounded-md border-0 py-3 px-2 w-[100%] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm" />/*}
                  {/* <input
                    type="date"
                    className="block  rounded-md border-0 py-3 px-2 w-[100%] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm"
                    defaultValue="2022-01-01"
                  /> */}
                  <div  
                        className='date-range-picker-container'
                        tabindex="100"
                        onMouseEnter={() => setDisplayDateRangePicker(true)}
                        onMouseLeave={() => setDisplayDateRangePicker(false)}>
                    <input
                      name='selectedKeywords'
                      value={`${formatDate(dateRange[0].startDate.getTime())} -- ${formatDate(dateRange[0].endDate.getTime())}`}
                      type="text"
                      placeholder="Search by name or keyword"
                      className="block  rounded-md border-0 py-3 px-2 w-[100%] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm"
                    />
                    <span className='date-range-picker'>{ displayDateRangePicker && <DateRange
                      editableDateInputs={false}
                      onChange={item => setDateRange([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={dateRange}
                      rangeColors={['#f33e5b', '#3ecf8e', '#fed14c']}
                    />}</span>
                    </div>
                </div>

                <div className="">
                  <p className="text-gray-600 text-base font-semibold pb-2">Advertiser Name or keyword</p>
                  <input
                    onChange={onFormDataEntriesChange}
                    name='selectedKeywords'
                    autoComplete="off"
                    value={adFormFields.selectedKeywords}
                    type="text"
                    placeholder="Search by name or keyword"
                    className="block  rounded-md border-0 py-3 px-2 w-[100%] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm"
                  />
                </div>
              </div>
              <div className="w-full flex justify-center items-center">
                <button 
                  className="bg-gradient-to-r from-green-500 to-blue-500 text-white hover:scale-105 download-btn mt-12 py-2.5 rounded-full text-base font-medium transition-all sm:w-3/5 w-full duration-300 ease-in-out " style={{
                  background:
                    "linear-gradient(135deg, rgb(0 147 255) 0%, rgb(238 29 210) 100%)",
                }}>
                  Search
                </button>
              </div>
              </form>
            </div>

          </div>
        </div>

        { adsArray.length > 0 && adsArray[0] !== undefined && <Results ads={adsArray} total={fetchedRawData.total}/> }

        <div className='bottom-loader-section' ref={targetRef}>{ fetchedRawData && loadingStatus ?  <Loader/> : <h2 className='search-list-end-message'>Wow, Such Empty!</h2> }</div>
        
      </div>
    </div>
  )
}

export default Home
