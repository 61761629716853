import { createSelector } from "reselect";

const selectTikTokAdsSlice = (state) => state.tiktokAds;

export const selectTikTokAds = createSelector(
  [selectTikTokAdsSlice],
  (tiktokAdsSlice) => tiktokAdsSlice.adsArrayData
)


export const selectFetchLoadingStatus = createSelector(
  [selectTikTokAdsSlice],
  (tiktokAdsSlice) => tiktokAdsSlice.isLoading
)


export const selectFetchedData = createSelector(
  [selectTikTokAdsSlice],
  (slice) => slice.fetchedData
)


export const selectHasMore = (state) => state.tiktokAds.hasMore