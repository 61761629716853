import React, { useRef } from 'react'
import ImageNotFound from './ImageNotFound';



export function formatDate(timestamp) {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

const ResultCard = ({heading, firstShown, lastShown, uniqueUsers, url}) => {

  const videoRef = useRef(null)

  const isVideoAvailable = url.length > 0 ? true : false

  const playVideo = () => {
    videoRef.current.play()
  }

  const pauseVideo = () => {
    videoRef.current.pause()
    videoRef.current.currentTime = 0;
  }


  return (
    <div className="max-w-sm rounded overflow-hidden bg-white shadow-lg">
      <a href={isVideoAvailable ? url[0].video_url: undefined} target={isVideoAvailable? '_blank' : undefined}>
        <div className="px-6 py-4">
            <p className='card-heading text-[rgba(22,24,35,.5)]'>{heading.length > 0 ? heading : '(Name unavailable)'}</p>
            <p className="text-gray-700 text-base">
                <p className='text-[rgba(22,24,35,.5)] text-[15px] font-medium'>First shown: <span className='text-[rgb(24,25,31)] font-semibold'>{formatDate(firstShown)}</span></p>
                <p className='text-[rgba(22,24,35,.5)] text-[15px] font-medium'>Last shown: <span className='text-[rgb(24,25,31)] font-semibold'>{formatDate(lastShown)}</span></p>
                <p className='text-[rgba(22,24,35,.5)] text-[15px] font-medium'>Unique users seen: <span className='text-[rgb(24,25,31)] font-semibold'>{uniqueUsers}</span></p>
            </p>
        </div>
        {/*<img className="w-full" src={`${url.length > 0 ? url[0].cover_img : '#'}`} alt={url.length > 0 ? `${heading.length > 0 ? heading : 'Ad-Image-Not-Found' }` : 'Ad-image-Not-Found'} />*/}
        {
          isVideoAvailable ? <video ref={videoRef} className='w-full' src={`${isVideoAvailable ? url[0].video_url : '#'}`} controls alt={heading.length > 0 ? heading : 'Ad-name-Not-Found'} onMouseEnter={playVideo} onMouseLeave={pauseVideo}/> : <ImageNotFound/>
        }
      </a>
    </div>
  )
}

export default ResultCard
