import { API_END_POINT } from "../../utils/JS_Utils/JS_Utils";
import { createAction } from "../../utils/reducer/reducer.utils";
import { TIKTOK_ADS_ACTION_TYPES } from "./tiktokAds.types";


export const setTikTokAds = (adsArrayData, fetchStatus) => createAction(TIKTOK_ADS_ACTION_TYPES.SET_ADS, { adsArrayData: adsArrayData, dataFetched: true })

export const fetchAdsStart = () => createAction(TIKTOK_ADS_ACTION_TYPES.FETCH_ADS_START)

export const fetchAdsSuccess = (adsDataArray) => createAction(TIKTOK_ADS_ACTION_TYPES.FETCH_ADS_SUCCESS, adsDataArray)

export const fetchAdsFailed = (error) => createAction(TIKTOK_ADS_ACTION_TYPES.FETCH_ADS_FAILED, error)

export const updateAdsStart = () => createAction(TIKTOK_ADS_ACTION_TYPES.UPDATE_ADS_START)

export const updateAdsSuccess = () => createAction(TIKTOK_ADS_ACTION_TYPES.UPDATE_ADS_SUCCESS)

export const fetchAdsAsync = () => async (dispatch, getState) => {
  dispatch(fetchAdsStart());
  
  const { startTime, endTime, selectedRegion, selectedKeywords} = getState().tiktokAds;

  try{
    console.log(`${API_END_POINT}/search?region=${selectedRegion}&start_time=${startTime}&end_time=${endTime}${selectedKeywords.length > 0 ? `${selectedKeywords[0].length > 0 ? `&keywords=${selectedKeywords}&query_type=1` : '' }` : '' }&limit=12`)
    const str = ''
    // console.log(str.length)
    // console.log(selectedKeywords)
    const response = await fetch(`${API_END_POINT}/search?region=${selectedRegion}&start_time=${startTime}&end_time=${endTime}${selectedKeywords.length > 0 ? `${selectedKeywords[0].length > 0 ? `&query=${selectedKeywords}&query_type=1` : '' }` : '' }&limit=12`,{
    // console.log(selectedRegion.toLowerCase()) &start_time=${startTime}&end_time=${endTime}
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    if(!response.ok){
      throw new Error("Failed to fetch data")
    }
    const adsFetchedData = await response.json()
    dispatch(fetchAdsSuccess(adsFetchedData))
  } catch(error){
    dispatch(fetchAdsFailed(error))
  } //>>---> this is our thunk
    
  
}


export const updateAdsAsync = () => async (dispatch, getState) => {
  dispatch(updateAdsStart());

  const { startTime, endTime, selectedRegion, searchID, selectedKeywords, offset } = getState().tiktokAds;
  
    try{
      console.log(`${API_END_POINT}/search?region=${selectedRegion}&start_time=${startTime}&end_time=${endTime}${selectedKeywords.length > 0 ? `${selectedKeywords[0].length > 0 ? `&keywords=${selectedKeywords}&query_type=1` : '' }` : '' }&search_id=${searchID}&offset=${offset+1}&limit=12`)
  
      const response = await fetch(`${API_END_POINT}/search?region=${selectedRegion}&start_time=${startTime}&end_time=${endTime}${selectedKeywords.length > 0 ? `${selectedKeywords[0].length > 0 ? `&query=${selectedKeywords}&query_type=1` : '' }` : '' }&search_id=${searchID}&offset=${offset+1}&limit=12`,{
      // console.log(selectedRegion.toLowerCase())
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      if(!response.ok){
        throw new Error("Failed to fetch data")
      }
      const adsFetchedData = await response.json()
      dispatch(updateAdsSuccess())
      dispatch(fetchAdsSuccess(adsFetchedData))
    } catch(error){
      dispatch(fetchAdsFailed(error))
    } //>>---> this is our thunk
    
  
}


export const setFormFieldsData = (formFields) => (dispatch) => {
  console.log(formFields);
  const startTimeStamp = Math.floor((formFields.startTime.getTime()) / 1000)
  const endTimeStamp = Math.floor((formFields.endTime.getTime()) / 1000)
  // console.log(startTimeStamp);
  const keywordsArray = formFields.selectedKeywords.split(/\s+/)
  // console.log(keywordsArray);


  const action = createAction(TIKTOK_ADS_ACTION_TYPES.SET_FORM_FIELDS, {...formFields, selectedKeywords: keywordsArray, startTime: startTimeStamp, endTime: endTimeStamp})
  dispatch(action);

  dispatch(fetchAdsAsync())
}